import "./index.css";
import * as serviceWorker from "./serviceWorker";

new window.Granim({
  element: "#canvas-basic",
  direction: "radial",
  isPausedWhenNotInView: true,
  states: {
    "default-state": {
      gradients: [
        ["#560094", "#560094"],
        ["#EF3125", "#EF3125"],
      ],
    },
  },
});

window.VanillaTilt.init(document.querySelectorAll(".logo, .actionpanel"), {
  max: 5,
  speed: 300,
});

/* ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
); */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
